<template>
  <div class="pair-to-help">
    <!-- <van-nav-bar title="结对帮扶" left-arrow @click-left="onClickLeft" /> -->
    <div class="pair-to-help-body">
      <div class="post-myword" v-if="showCard && pairSuccess=='0'">
        <input
          type="text"
          name
          id="myword"
          placeholder="搜索党员姓名"
          @change.enter="search"
          v-model="searchName"
        />
        <van-button round type="danger" @click="search" class="searchBtn">搜索</van-button>
      </div>
      <ul class="pair-to-help-list" v-if="pairSuccess!=='0' && bingInfo.id">
        <li @click="goDetail(bingInfo.id)">
          <div class="info">
            <div class="img">
              <img :src="bingInfo.avatar" alt srcset />
            </div>
            <div class="republicer">
              <h3>结对党员：{{bingInfo.realName}}</h3>
              <div class="part-name">
                <span>所在支部：</span>
                <p>{{bingInfo.deptName}}</p>
              </div>
              <div class="birth">
                <span>出生日期：</span>
                <p>{{bingInfo.birthday.split(' ')[0]}}</p>
              </div>
            </div>
          </div>
          <div class="pair" @click.stop="unPair(bingInfo.id)" :style="{color:'#555555'}">
            <img src="./../../assets/img/release.png" alt />
            <span>解绑</span>
          </div>
        </li>
      </ul>
      <ul class="pair-to-help-list" v-if="pairSuccess==0">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :offset="70"
            :error.sync="error"
            error-text="暂无匹配的党员"
          >
            <li v-for="(item,index) in infoList" :key="index" @click="goDetail(item.id)">
              <div class="info">
                <div class="img">
                  <img :src="item.avatar" alt srcset />
                </div>
                <div class="republicer">
                  <h3>结对党员：{{item.realName}}</h3>
                  <div class="part-name">
                    <span>所在支部：</span>
                    <p>{{item.deptName}}</p>
                  </div>
                  <div class="birth">
                    <span>出生日期：</span>
                    <p>{{item.birthday.split(' ')[0]}}</p>
                  </div>
                </div>
              </div>
              <div class="pair" @click.stop="toPair(item.id)">
                <!-- <img src="./../../assets/img/release.png" alt /> -->
                <img src="./../../assets/img/pair.png" alt srcset />
                与他结对
              </div>
            </li>
          </van-list>
        </van-pull-refresh>
      </ul>
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import {
  requestPage,
  requestBind,
  requestUnbind,
  requestUserInfo,
  requestDetail
} from '@/api/pairToHelp';
export default {
  data() {
    return {
      active: 0,
      activeType: 'all',
      infoList: [],
      pairSuccess: '0',
      currentPage: 1,
      loading: false,
      finished: false,
      error: false,
      refreshing: false,
      searchName: '',
      bingInfo: {},
      showCard: false,
      getRecordStatus: false
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mainPage' });
    },
    //请求用户信息
    getUserInfo() {
      requestUserInfo().then(res => {
        if (res.success) {
          this.pairSuccess = res.data.supportId;
          if (this.pairSuccess !== '0') {
            // console.log(this.pairSuccess);
            this.getHimInfo();
          }
        }
      });
    },
    //请求与他绑定的数据
    getHimInfo() {
      requestDetail({ id: this.pairSuccess }).then(res => {
        if (res.success) {
          // console.log(res);
          this.bingInfo = res.data;
        }
      });
    },
    //刷新数据
    onRefresh() {
      // 清空列表数据

      this.infoList = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      this.currentPage = 1;
      this.onLoad();
    },
    //请求更多数据
    getMorePage() {
      this.getRecordStatus = true;
      requestPage({
        size: 4,
        current: this.currentPage
      })
        .then(res => {
          if (res.success) {
            //加载完成
            this.showCard = true;
            this.loading = false;
            this.refreshing = false;
            this.currentPage++; //一定要先进行页数加加
            if (res.data.records.length === 0) {
              this.finished = true;
              return false;
            }
            //用concat去拼接
            // let arr = this.infoList.concat(res.data.records);
            let arr = [...this.infoList, ...res.data.records];
            this.infoList = arr;
            this.getRecordStatus = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.getRecordStatus = false;
        });
      // this.getRecordStatus = false;
    },
    //搜索后出现的列表
    getPage(searchName) {
      requestPage({
        realName: searchName
      })
        .then(res => {
          // console.log(res);
          this.infoList = res.data.records;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //进入详情
    goDetail(id) {
      this.$router.push({ name: 'infoDetail', params: { id: id } });
    },
    //搜索
    search() {
      this.finished = false;
      this.getPage(this.searchName);
    },
    //结对
    toPair(id) {
      requestBind({
        id: id
      }).then(res => {
        if (res.code == 400) {
          Notify({ type: 'warning', message: res.msg });
        }
        if (res.success) {
          this.infoList = [];
          this.showCard = false;
          this.pairSuccess = res.data.id;
          this.bingInfo = res.data;
        }
      });
    },
    //解绑
    unPair(id) {
      requestUnbind({
        id
      }).then(res => {
        if (res.success) {
          this.pairSuccess = '0';
          this.finished = false;
          this.error = false;
          this.loading = true;
          this.currentPage = 1;
          this.getMorePage();
        }
      });
    },
    //加载更多数据
    onLoad() {
      // this.getMorePage();
      // this.loading = false;
      if (!this.getRecordStatus) {
        console.log(!this.getRecordStatus);
        // const timer = setTimeout(() => {
        this.getMorePage();
        this.loading = false;
        //   clearTimeout(timer);
        // }, 500);
      }
    }
  },
  watch: {
    infoList() {
      if (this.infoList.length == 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    searchName() {
      if (this.searchName == '' && this.infoList.length == 0) {
        this.currentPage = 1;
        this.getMorePage();
      }
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.pair-to-help {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .pair-to-help-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .post-myword {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 88 * $px;
      input {
        flex: 1;
        height: 60 * $px;
        border: 0;
        background: #fff;
        border-radius: 30 * $px;
        font-size: 24 * $px;
        margin: 0 10 * $px;
        text-align: center;
      }
      .searchBtn {
        width: 150 * $px;
        height: 60 * $px;
        margin: 0 10 * $px;
        line-height: 60 * $px;
      }
    }
    .pair-to-help-list {
      padding: 0 20 * $px;
      li {
        background: #fff;
        margin-top: 20 * $px;
        border-radius: 8 * $px;
        padding: 20 * $px;
        font-size: 24 * $px;
        display: flex;
        flex-direction: column;
        .info {
          display: flex;
          border-bottom: 1 * $px solid #f3f3f3;
          padding-bottom: 20 * $px;
          box-sizing: border-box;
          .img {
            width: 160 * $px;
            height: 160 * $px;
            background: url('./../../assets/img/avatar.png') center center;
            border-radius: 8px;
            margin-right: 15 * $px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .republicer {
            flex: 1;
            font-size: 22 * $px;
            color: #999;
            h3 {
              font-size: 28 * $px;
              color: #222;
            }
            & > div {
              display: flex;
              &.part-name {
                margin: 10 * $px 0;
              }
              span {
                width: 140 * $px;
              }
            }
          }
        }
        .pair {
          height: 80 * $px;
          width: 100%;
          color: #ffc600;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28 * $px;
          img {
            width: 36 * $px;
            height: 32 * $px;
          }
        }
      }
    }
  }
}
</style>